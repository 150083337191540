import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 完整引入 element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// 引入图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import YsPagination from './component/YsPagination.vue';

// 重置样式表
import 'normalize.css'

const app = createApp(App)

window.baseURL =  '/api'
if (process.env.VUE_APP_BASE_API_URL) {
  window.baseURL = process.env.VUE_APP_BASE_API_URL
}

import './style/index.less'


app.use(store)
app.use(router)
app.use(ElementPlus, {locale: zhCn})
app.component('YsPagination', YsPagination)
app.mount('#app')
// 注册所有的图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
