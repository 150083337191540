import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/layout',
    redirect: '/layout/users',
    name: 'Layout',
    component: () => import('@/layout'),
    children: [
      {
        path: 'users',
        component: () => import('@/views/users'),
      },
      {
        path: 'good',
        component: () => import('@/views/good'),
      },
      {
        path: 'order',
        component: () => import('@/views/order'),
      },
      {
        path: 'withdrawal',
        component: () => import('@/views/withdrawal'),
      },
      {
        path: 'trusteeship',
        component: () => import('@/views/trusteeship'),
      },
      {
        path: 'types',
        component: () => import('@/views/types'),
      },
      {
        path: 'banner',
        component: () => import('@/views/banner'),
      },
      {
        path: 'info',
        component: () => import('@/views/info'),
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
  },
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: () => import('@/views/users'),
  // },
]

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
  routes,
})

export default router
