<template>
    <router-view/>
</template>

<style lang="less">
  body,html,#app{
    width: 100%;
    height: 100%;
  }
</style>
