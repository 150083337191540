import { createStore } from 'vuex'
import user from './user'
import persistedState from 'vuex-persistedstate'




/* 状态管理器实例化 */
const store = createStore({
  plugins: [
      persistedState({
          storage: window.sessionStorage
      })
  ],
  modules: {
      user,
  }
})

export default store