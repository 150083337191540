/**
 * @description 定义用户相关的状态数据管理
 */


/* 定义初始化数据 */
// let state = {
//     token: '1213213',
//     userInfo: {},
//     userRights: {},
//     userMenus: []
// }

// let cache = sessionStorage.getItem(USER_STATE)
// if (!!cache) {
//     state = JSON.parse(decodeApi(cache))
// }

const user = {
    namespaced: true,
    state: {
        token: '',
        tokenTime: 0,
        userInfo: {},
    },
    getters: {
        token(state) {
            return state.token
        },
        tokenTime(state) {
            return state.tokenTime
        },
        userInfo: _state => _state.userInfo,
    },
    mutations: {
        setToken(state, _token) {
            state.token = _token
            // sessionStorage.setItem(USER_STATE, encodeApi(JSON.stringify(state)))
        },
        setUserInfo(state, _userInfo) {
            state.userInfo = _userInfo
        },
        setTokenTime(state, _time) {
            state.tokenTime = _time
        },
    }
}

export default user