<template>
    <div class="page-layout">
        <header>
            <slot name="header"/>
        </header>
        <main :class="[showFooter || 'no-footer']">
            <slot/>
        </main>
        <footer v-if="showFooter">
            <el-pagination v-model:current-page="pageNo"
                v-if="show"
                v-model:page-size="pageSize"
                :page-sizes="[10, 20, 30, 40]"
                small
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageSize: 20,
            pageNo: 1,
        }
    },
    props: {
        total: Number,
        showFooter: {
            type: Boolean,
            default: true
        },
        show:{
            default:true,
        }
    },
    mounted() {
        this.$parent.getList({pageSize: this.pageSize, pageNo: this.pageNo});
    },
    methods: {
        // 修改size
        handleSizeChange() {
            this.$parent.getList({pageSize: this.pageSize, pageNo: this.pageNo});
        },
        // 修改页码
        handleCurrentChange() {
            this.$parent.getList({pageSize: this.pageSize, pageNo: this.pageNo});
        },
        queryFunc() {
            this.$parent.getList({pageSize: this.pageSize, pageNo: this.pageNo});
        }
    }
}
</script>

<style lang="less" scoped>
.page-layout {
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    > footer,
    > header {
        height: 32px;
        padding: 8px 12px;
    }
    > header {
        text-align: right;
        ::v-deep .el-input {
            width: 220px;
        }
        ::v-deep .el-button,
        ::v-deep .el-input {
            margin-left: 10px;
        }
    }
    > main {
        height: calc(100% - 49px * 2);
        &.no-footer {
            height: calc(100% - 49px);
        }
    }
}
</style>
